body{
    color:#3E434A!important
}
.xHidden{
    display:none!important
}
.bluetext{
    color:#3CB5D8!important
}
.bluebg{
    background: #3CB5D8 !important;
    color: #FFF;
}
.darkbluebg{
    background: #1D6281;
    color: #FFF;
}
.greybg{
    background: #3E434A;
    color: white;
}
.disabled{
    pointer-events: none;
}
.navlink span{
    vertical-align: middle;
}
i img{
 filter: invert();
 width:30px;
}
.bluetext i img{
  filter: invert() brightness(0.5) sepia(1) hue-rotate(145deg) saturate(6);
  width:30px;
}
table td{
    text-align: center; 
    vertical-align: middle;
}

table p {
    margin-bottom: 0;
}

table td {
    padding: 1rem 0.5rem !important;
}
ul.faq{
    list-style: none;
    width:100%
}
ul.faq li{
    display: inline-block;
    width: 10em;
    margin:0.25em
}
ul.faq li button{
        color: #FFF!important;
        height:85px
}
.answer{
    min-height:12rem!important
}

/* Type */
h1 {
    font-weight: 700 !important;
    margin-bottom: 1.5rem !important;
}

h2 {
    margin-bottom: 1rem !important;
}
/* Cards */
.card{
    filter: drop-shadow(0 0.15rem 0.15rem rgba(0, 0, 0, 0.25));
    border-radius: 1rem !important;
    overflow: hidden;
    margin-top: 1rem;
}
.card .bg-white{
    z-index:5
}
p.feed{
    height: 148px!important;
    overflow: hidden!important;
}
.card p:not('rank'){
    height: 148px;
    overflow: hidden;
}
.card .card-img-top{
    width:100%;
    /*height: 170px !important;*/
    overflow: hidden!important;
}

/*@media (min-width: 1200px) {
    .card .card-img-top {
        height: 250px !important;
    }
}*/

div .featured{
    /*width: 100%;*/
    /*max-width:28rem;*/
    float: right;
    margin-top: 0.5em;
}

/*@media (min-width: 992px) {
    div .featured {
        max-width:19rem;
    }
}*/

/*@media (min-width: 1390px) {
    div .featured {
        max-width:28rem;
    }
}*/

.card .featured{
    height:15rem;
    width:100%;
    float: left;
    display: inline
    /* margin-top:-2rem */
}

@media (min-width: 768px) {
    .featured .card{
        height: auto;
    }
}
.featured p{
    height:100px;
    overflow: hidden;
}
.featured .logo{
    height:2rem;
    width:5rem;
}
.featured img:not(.logo){
    width:100%
}
.featured-container{
    margin:2rem auto 0 auto;
    border-radius: 1rem;
    padding-top: 1.5rem !important;
}
@media(min-width: 992px) {
    .featured-container{
        height:30rem;
        margin:2rem auto 15rem auto;
    }
}
.faqs-video {
    max-width: 500px;
}

/* Sidebar */
.sidebar {
    background-color: #3e434a;
    color: white;
}

/* Buttons */
.feed-cta {
    padding: .5rem 2.5rem !important;
}

@media (min-width:  1500px) {
    .feed-cta {
        width: 45%;
    }
}

.feed-cta:hover {
    background-color: #1D6281 !important;
}

/* Footer */
footer {
    font-size: 0.875rem;
}

.navbar-brand {
    font-size: 0.875rem !important;
}

.points-total {
    text-align:  center;
    color: darkgrey;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 25px 20px;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow:  0 1px 3px rgb(0 0 0 / 25%);
    margin-bottom: 1rem;
}

.points-total h1 {
    margin-bottom: 0 !important;
}

@media (min-width: 992px) {
    .points-total {
        float: right;
    }
}